import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SecurityService } from 'admin-core/services';
import { HREF_API } from 'admin-shared/models';
import { DialogueService } from 'admin-shared/services';
import { isStringNotEmpty } from 'admin-shared/toolkit/string.toolkit';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private dialogueService: DialogueService, private securityService: SecurityService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            const error = err.error?.message || err.statusText;
            console.error(err);

            if (!!this.securityService.userValue) {
                switch (err.status) {
                    case 401: {
                        // auto logout if 401 response returned from api
                        this.securityService.logout();
                        return;
                    }
                    case 400:
                    case 403:
                    case 404:
                    case 405:
                    case 409:
                    case 471: {
                        this.errorDialogue(err.status, request);
                    }
                }
            } else {
                switch (err.status) {
                    case 472: {
                        this.errorDialogue(err.status, request);
                    }
                }
            }

            return throwError(error);
        }))
    }

    private errorDialogue = (status: number, request: HttpRequest<any>): void => {
        if (isStringNotEmpty(request.url) && request.url.startsWith(HREF_API)) {
            const method = status === 405 ? `.${request.method}` : '';
            this.dialogueService.error(`error.${status}${method}`);
        }
    }
}
