import { Injectable } from '@angular/core';
import { SecurityService } from 'admin-core/services';
import { AuthorityAction, GrantedAuthority, SecuredDomain, User } from 'admin-shared/models';
import { asArray, isArrayNotEmpty } from 'admin-shared/toolkit/array.toolkit';
import { isDefined } from 'admin-shared/toolkit/object.toolkit';
import { Observable, of, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class UserAuthoritiesService {
    SCREEN_APPLICATION_SETTINGS: Observable<GrantedAuthority>;
    SCREEN_USER_SETTINGS: Observable<GrantedAuthority>;
    SCREEN_CUSTOMERS: Observable<GrantedAuthority>;
    SCREEN_CUSTOMERS_EXPIRED: Observable<GrantedAuthority>;
    SCREEN_DASHBOARD: Observable<GrantedAuthority>;

    APPLICATION_SETTINGS: Observable<GrantedAuthority>;
    CUSTOMER: Observable<GrantedAuthority>;
    USER: Observable<GrantedAuthority>;

    private destroy$: Subject<void> = new Subject();

    constructor(private securityService: SecurityService) {
        this.SCREEN_APPLICATION_SETTINGS = this.grantedAuthority$(SecuredDomain.SCREEN_APPLICATION_SETTINGS);
        this.SCREEN_USER_SETTINGS = this.grantedAuthority$(SecuredDomain.SCREEN_USER_SETTINGS);
        this.SCREEN_CUSTOMERS = this.grantedAuthority$(SecuredDomain.SCREEN_CUSTOMERS);
        this.SCREEN_CUSTOMERS_EXPIRED = this.grantedAuthority$(SecuredDomain.SCREEN_CUSTOMERS_EXPIRED);
        this.SCREEN_DASHBOARD = this.grantedAuthority$(SecuredDomain.SCREEN_CUSTOMERS);

        this.APPLICATION_SETTINGS = this.grantedAuthority$(SecuredDomain.APPLICATION_SETTINGS);
        this.CUSTOMER = this.grantedAuthority$(SecuredDomain.CUSTOMER);
        this.USER = this.grantedAuthority$(SecuredDomain.USER);
    }

    canNotCreate = (securedDomain: SecuredDomain | SecuredDomain[], unsubscribe$: Subject<void>): Observable<boolean> => this.canCreate(securedDomain).pipe(
        filter(canCreate => canCreate === false),
        map(() => true),
        takeUntil(unsubscribe$),
    );
    canCreate = (securedDomain: SecuredDomain | SecuredDomain[]): Observable<boolean> => this.canDoAction$(of(securedDomain), AuthorityAction.CREATE);
    canCreate$ = (securedDomain$: Observable<SecuredDomain | SecuredDomain[]>): Observable<boolean> => this.canDoAction$(securedDomain$, AuthorityAction.CREATE);

    canRead = (securedDomain: SecuredDomain | SecuredDomain[]): Observable<boolean> => this.canDoAction$(of(securedDomain), AuthorityAction.READ);
    canRead$ = (securedDomain$: Observable<SecuredDomain | SecuredDomain[]>): Observable<boolean> => this.canDoAction$(securedDomain$, AuthorityAction.READ);

    canNotUpdate = (securedDomain: SecuredDomain | SecuredDomain[], unsubscribe$: Subject<void>): Observable<boolean> => this.canUpdate(securedDomain).pipe(
        filter(canUpdate => canUpdate === false),
        map(() => true),
        takeUntil(unsubscribe$),
    );
    canUpdate = (securedDomain: SecuredDomain | SecuredDomain[]): Observable<boolean> => this.canUpdate$(of(securedDomain));
    canUpdate$ = (securedDomain$: Observable<SecuredDomain | SecuredDomain[]>): Observable<boolean> => this.canDoAction$(securedDomain$, AuthorityAction.UPDATE);

    canDelete = (securedDomain: SecuredDomain | SecuredDomain[]): Observable<boolean> => this.canDoAction$(of(securedDomain), AuthorityAction.DELETE);
    canDelete$ = (securedDomain$: Observable<SecuredDomain | SecuredDomain[]>): Observable<boolean> => this.canDoAction$(securedDomain$, AuthorityAction.DELETE);

    canDoAction$ = (securedDomain$: Observable<SecuredDomain | SecuredDomain[]>, action: AuthorityAction): Observable<boolean> => securedDomain$.pipe(
        map(securedDomain => asArray(securedDomain)),
        withLatestFrom(this.securityService.user$),
        map(
            ([domainList, user]) => isArrayNotEmpty(domainList) && asArray(user.authorities)
                .filter(auth => domainList.includes(auth.authDomain) && auth[action] === true).length > 0,
        ),
    );

    grantedAuthority$ = (securedDomain: SecuredDomain): Observable<GrantedAuthority> => this.securityService.user$.pipe(
        filter(isDefined),
        map((user: User) => asArray(user.authorities).find(auth => auth.authDomain === securedDomain)),
    );
}
