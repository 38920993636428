<mat-nav-list>
    <ng-container *ngFor="let item of routes">
        <div class="nested-menu" *ngIf="item.hasSubmenu; else tplSingleMenu">
            <a mat-list-item (click)="item.showSubmenus = !item.showSubmenus">
                <mat-icon class="sidenav-icon">{{ item.data.icon }}</mat-icon>
                {{ item.data.text }}
            </a>
            <ul class="nested submenu pl-5" [class.expand]="item.showSubmenus">
                <li *ngFor="let itemSubmenu of item.submenus">
                    <a href="javascript:void(0)"  (click)="onClickLink()">
                        <span>{{itemSubmenu.text | translate}}</span>
                    </a>
                </li>
            </ul>
        </div>

        <ng-template #tplSingleMenu>
            <a *ngIf="(authoritiesService[item.securedDomain] | async)?.authRead" mat-list-item [routerLinkActive]="'active'" (click)="onClickLink()" [routerLink]="['/' + item.path]">
                <mat-icon class="sidenav-icon" [ngClass]="item.data.iconClass">{{ item.data.icon }}</mat-icon>
                {{ item.data.text | translate }}
            </a>
        </ng-template>

        <div *ngIf="item.divider" class="my-5">
            <mat-divider></mat-divider>
        </div>
    </ng-container>
</mat-nav-list>
