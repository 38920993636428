<ng-container *ngIf="canRead$ | async; else tplError;">
    <ng-content></ng-content>
</ng-container>

<ng-template #tplError>
    <section id="access-denied-msg" class="access-denied">
        <div class="err-icon">
            <mat-icon class="sidenav-icon">visibility_off</mat-icon>
        </div>
        <div class="err-msg">
            <h3 class="mb-3">Acces limitat pentru datele din acest ecran. </h3>
            <h4>Contactati administratorul</h4>
        </div>
    </section>
</ng-template>
