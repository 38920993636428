import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SecuredContentComponent } from './screen-content/secured-content.component';
import { StaticFormFieldComponent } from './static-form-field/static-form-field.component';
import { TableActionsComponent } from './table-actions/table-actions.component';

export const COMPONENTS = [
    ConfirmDialogComponent,
    ErrorDialogComponent,
    PageNotFoundComponent,
    SecuredContentComponent,
    StaticFormFieldComponent,
    TableActionsComponent,
];

export * from './confirm-dialog/confirm-dialog.component';
export * from './error-dialog/error-dialog.component';
export * from './page-not-found/page-not-found.component';
export * from './screen-content/secured-content.component';
export * from './static-form-field/static-form-field.component';
export * from './table-actions/table-actions.component';
