<h1 class="conf-title" mat-dialog-title>
    <mat-icon class="err-icon" aria-hidden="false" aria-label="ERROR">warning</mat-icon>
    {{data.title | translate}}
</h1>
<div class="conf-message" mat-dialog-content>
    <p class="pb-5">{{data.message | translate}}</p>
</div>
<div class="conf-footer" mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()" translate="btn.not_yet"></button>
    <button mat-stroked-button color="warn" [mat-dialog-close]="true" cdkFocusInitial translate="btn.confirm"></button>
</div>
