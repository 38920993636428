import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationResult, User } from 'admin-shared/models';
import { clearSessionStorage, getSessionExpiration, getUserSession, setUserSessionStorage } from 'admin-shared/toolkit/security.toolkit';
import { isBefore } from 'date-fns';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class SecurityService {
    private userSubject$: BehaviorSubject<User> = new BehaviorSubject<User>(
        getUserSession(),
    );
    public user$: Observable<User> = this.userSubject$.asObservable();

    constructor(private router: Router, private http: HttpClient) {
    }

    public get userValue(): User {
        return this.isLoggedIn() ? this.userSubject$.value : null;
    }

    public isLoggedIn(): boolean {
        const sessionExpiration = getSessionExpiration();
        return !!sessionExpiration && isBefore(new Date(), sessionExpiration);
    }

    login(username: string, password: string, remember: boolean = false) {
        return this.http.get<any>('/api/init').pipe(
            switchMap(() => (
                this.http.post<AuthenticationResult>('/api/security/authenticate', { username, password, remember })
                    .pipe(
                        tap(authResult => {
                            setUserSessionStorage(authResult);
                            this.userSubject$.next(authResult.user);
                        }),
                    )
            )),
        );
    }

    logout() {
        // remove user from session storage and set current user to null
        clearSessionStorage();
        this.userSubject$.next(null);
        this.router.navigate(['/login']);
    }
}
