import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { SecurityService } from 'admin-core/services';
import { ConfigStoreService, GrantedAuthority, HREF_API, StateStatus, StoreService, StoreServiceReadonly, ViewState } from 'admin-shared/models';
import { asArray } from 'admin-shared/toolkit/array.toolkit';
import { parseAuditableDate, setDateToString } from 'admin-shared/toolkit/item.toolkit';
import { isStringNotEmpty } from 'admin-shared/toolkit/string.toolkit';
import { Observable, of, Subscription, switchMap, withLatestFrom } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export abstract class ComponentStoreService<T> extends ComponentStore<ViewState<T>> {

    public readonly data$ = this.select(s => s.entity);
    public readonly status$ = this.select(s => s.status);

    protected readonly isArray: boolean;
    protected readonly initialState: T | T[];

    protected constructor(protected config: ConfigStoreService, initialState?: T | T[]) {
        super({ entity: initialState, status: StateStatus.NOT_LOADED });

        this.isArray = !!initialState && Array.isArray(initialState);
        this.initialState = initialState;
    }

    /**
     * used moore when subscribing component is destroyed,
     * to not keep data if the service instance is kept because is provided at the module level
     */
    resetState = (): void => {
        this.setState({ entity: this.initialState, status: StateStatus.NOT_LOADED });
    };

    protected mapItem = (item: T | any): T => {
        return parseAuditableDate(item);
    };

    protected mapItemList = (itemList: (T | any)[]): T[] => {
        return itemList.map(item => this.mapItem(item));
    };

    protected mapData = (data: T | any | (T | any)[]): T | T[] => {
        return this.isArray ? this.mapItemList(data as (T | any)[]) : this.mapItem(data as T | any);
    };

    get isLoaded(): boolean {
        return this.get(state => state.status) === StateStatus.LOADED;
    }

    get isNotLoaded(): boolean {
        return this.get(state => state.status) === StateStatus.NOT_LOADED;
    }

    get state(): ViewState<T> {
        return this.get();
    }
}

export abstract class ReadonlyStoreService<T> extends ComponentStoreService<T> implements StoreServiceReadonly<T> {
    protected baseHref = HREF_API;
    protected idProperty = 'uuid';

    protected readonly injector: Injector;
    protected readonly httpClient: HttpClient;
    protected readonly securityService: SecurityService;

    protected readonly userAuthority: GrantedAuthority;

    public load = this.effect($ => $.pipe(
        switchMap(() => {
            if (!this.baseHref) {
                console.error('[' + this.constructor.name + '] There\'s no PATH object to load data for');
                return of(this.isArray ? [] : null);
            }
            this.initLinks();
            this.patchState({ status: StateStatus.REQUESTING });
            return this.httpClient.get<T>(this.baseHref).pipe(
                map(this.mapData),
                tapResponse(
                    backendResponse => this.patchState({
                        entity: backendResponse,
                        status: StateStatus.LOADED,
                    }),
                    _ => this.patchState({ status: StateStatus.ERROR }),
                ),
            );
        }),
    ));

    public loadUUID = this.effect<string>(uuid$ => uuid$.pipe(
        switchMap(uuid => {
            if (!this.baseHref) {
                console.error('[' + this.constructor.name + '] There\'s no LINK object to load data for UUID', uuid);
                return of(this.isArray ? [] : null);
            }

            this.patchState({ status: StateStatus.REQUESTING });

            return this.httpClient.get<T>(`${this.baseHref}${uuid}`).pipe(
                map(this.mapData),
                tapResponse(
                    backendResponse => this.patchState({
                        entity: backendResponse,
                        status: StateStatus.LOADED,
                    }),
                    _ => this.patchState({ status: StateStatus.ERROR }),
                ),
            );
        }),
    ));

    protected constructor(protected config: ConfigStoreService, initialState?: T[] | T) {
        super(config, initialState);

        this.baseHref = config.baseHref;
        if (this.baseHref.lastIndexOf('/') !== this.baseHref.length - 1) {
            this.baseHref += '/';
        }

        this.idProperty = isStringNotEmpty(config.idProperty) ? config.idProperty : this.idProperty;

        this.injector = this.config.injector;
        this.httpClient = this.injector.get(HttpClient);
        this.securityService = this.injector.get(SecurityService);

        if (!!this.config.securedDomain) {
            this.userAuthority = asArray(this.securityService.userValue?.authorities).find(auth => auth.authDomain === this.config.securedDomain);
        }

        this.init();
    }

    protected init = () => {
        if (this.config.doInit === false) {
            return;
        }

        // execute timeout to exit from constructor
        setTimeout(() => {
            this.initLinks();
            if (this.config.loadData !== false) {
                this.load();
            }
        });
    };

    protected initLinks: () => void = () => {
    };

    protected entityHref = (entity: Partial<T>): string => `${this.baseHref}${entity[this.idProperty]}`;
}

export abstract class CrudStoreService<T> extends ReadonlyStoreService<T> implements StoreService<T> {

    protected doAfterActionPatch: () => void = () => {};

    public create = this.effect<T>((itemToSave$: Observable<Partial<T>>) => itemToSave$.pipe(
        switchMap(item => {
            if (!this.baseHref) {
                console.error('[' + this.constructor.name + '] There\'s no LINK object to create');
                return of(this.isArray ? [] : null);
            }

            return this.httpClient.post(this.baseHref, setDateToString(item)).pipe(
                map(this.mapItem),
                withLatestFrom(this.select(s => s.entity)),
                tapResponse(
                    ([persistedEntity, stateValue]) => {
                        this.patchStateValue(stateValue, persistedEntity);
                        this.doAfterActionPatch();
                    },
                    (error) => {
                        console.error('[' + this.constructor.name + '] unable to create entity', error);
                        return this.patchState({ status: StateStatus.ERROR });
                    },
                ),
            );
        }),
    ));

    public update = this.effect<T>((itemToUpdate$: Observable<Partial<T>>) => itemToUpdate$.pipe(
        switchMap(entity => this.httpClient.put(this.entityHref(entity), setDateToString(entity)).pipe(
            map(this.mapItem),
            withLatestFrom(this.select(s => s.entity)),
            tapResponse(
                ([persistedEntity, state]) => {
                    this.patchStateValue(state, persistedEntity);
                    this.doAfterActionPatch();
                },
                _ => this.patchState({ status: StateStatus.ERROR }),
            ),
        )),
    ));

    public delete = this.effect<T>((itemToDelete$: Observable<T>) => itemToDelete$.pipe(
        switchMap(entity => this.httpClient.delete<T>(this.entityHref(entity)).pipe(
            withLatestFrom(this.select(s => s.entity)),
            tapResponse(
                ([, state]) => {
                    const newState = this.isArray ?
                        asArray(state).filter(stateEntity => {
                            return !this.isTheSameEntity(stateEntity, entity);
                        }) : null;

                    this.patchState({ entity: newState });
                    this.doAfterActionPatch();
                },
                _ => this.patchState({ status: StateStatus.ERROR }),
            ),
        )),
    ));

    protected constructor(protected config: ConfigStoreService, initialState?: T[] | T) {
        super(config, initialState);
    }

    public save = (itemToSave: void & T): Subscription => {
        return isStringNotEmpty(itemToSave[this.idProperty]) ? this.update(itemToSave) : this.create(itemToSave);
    };

    protected isTheSameEntity = (arrayEntity: T, entity: T | Partial<T>): boolean => arrayEntity[this.idProperty] === entity[this.idProperty];

    protected patchStateValue = (currentStateValue: T | T[], persistedEntity: T): void => {
        let updatedStateValue: T | T[] = persistedEntity;

        if (this.isArray) {
            updatedStateValue = [...asArray(currentStateValue)];
            const existingIndex = updatedStateValue.findIndex(stateEntity => this.isTheSameEntity(stateEntity, persistedEntity));

            if (existingIndex >= 0) {
                updatedStateValue.splice(existingIndex, 1, persistedEntity);
            } else {
                updatedStateValue.push(persistedEntity);
            }
        }
        this.patchState({ entity: updatedStateValue });
    };
}

export abstract class EntityStoreServiceReadonly<T> extends ReadonlyStoreService<T> {
    public readonly data$ = this.select(s => s.entity as T);

    protected constructor(protected config: ConfigStoreService) {
        super(config, null);
    }
}

export abstract class EntityStoreService<T> extends CrudStoreService<T> {
    public readonly data$ = this.select(s => s.entity as T);

    protected constructor(protected config: ConfigStoreService) {
        super(config, null);
    }
}

export abstract class ListStoreServiceReadonly<T> extends ReadonlyStoreService<T> {
    public readonly data$ = this.select(s => s.entity as T[]);

    protected constructor(protected config: ConfigStoreService) {
        super(config, []);
    }
}

export abstract class ListStoreService<T> extends CrudStoreService<T> {
    public readonly data$ = this.select(s => s.entity as T[]).pipe(
        startWith([]),
    );

    public getByUuid = (uuid: string) => {
        return asArray(this.state.entity).find(item => item['uuid'] === uuid);
    };

    protected constructor(protected config: ConfigStoreService) {
        super(config, []);
    }
}
