<div class="mat-form-field mat-primary mat-form-field-appearance-legacy mat-form-field-can-float mat-form-field-should-float mat-form-field-has-label">
    <div class="mat-form-field-wrapper">
        <div class="mat-form-field-flex">
            <div class="mat-form-field-infix">
                <span>{{value}}</span>
                <span class="mat-form-field-label-wrapper">
                    <label class="mat-form-field-label" [translate]="label"></label>
                </span>
            </div>
        </div>
    </div>
</div>