import { AuthenticationResult, DATETIME_FORMAT, EXPIRE_KEY, GrantedAuthority, SecuredDomain, TOKEN_KEY, User, USER_KEY } from 'admin-shared/models';
import { stringToDateTime } from 'admin-shared/toolkit/date.toolkit';
import { add, format } from 'date-fns';

export const cleanUserDataStorage = () => {
    window.localStorage.removeItem(EXPIRE_KEY);
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(USER_KEY);
};

export const clearSessionStorage = () => {
    window.localStorage.clear();
};

export const setUserSessionStorage = (authResult: AuthenticationResult) => {
    cleanUserDataStorage();
    const expiresAt = add(new Date(), { seconds: authResult.expiresIn });

    window.localStorage.setItem(EXPIRE_KEY, format(expiresAt, DATETIME_FORMAT));
    window.localStorage.setItem(TOKEN_KEY, authResult.user.token);
    window.localStorage.setItem(USER_KEY, JSON.stringify(authResult.user));
};

export const getUserSession = (): User => {
    return JSON.parse(window.localStorage.getItem(USER_KEY));
};

export const getSessionExpiration = (): Date => {
    const expireKey = window.localStorage.getItem(EXPIRE_KEY);
    return stringToDateTime(expireKey);
};

export const buildGrantedAuthority = (domain: SecuredDomain, params: { [key: string]: boolean } = {}): GrantedAuthority => ({
    authDomain: domain,
    authRead: false,
    authCreate: false,
    authDelete: false,
    authUpdate: false,
    ...params,
});

export const findGrantedAuthority = (domain: SecuredDomain, authorities: GrantedAuthority[] = []): GrantedAuthority =>
    authorities.find(auth => auth.authDomain === domain);
