import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ErrorDialogComponent } from 'admin-shared/components';
import { Subject, takeUntil } from 'rxjs';

@Injectable()
export class DialogueService {

    constructor(private dialog: MatDialog) {}

    public confirm = (message: string, onSuccess: () => void): void => {
        const dialogUnsubscribe$: Subject<void> = new Subject<void>();
        this.dialog.open(ConfirmDialogComponent, { data: { message, title: 'title.confirm.delete' } })
            .afterClosed()
            .pipe(takeUntil(dialogUnsubscribe$))
            .subscribe((result: boolean) => {
                if (result === true) {
                    onSuccess();
                }
                dialogUnsubscribe$.next();
                dialogUnsubscribe$.complete();
            });
    };

    public error = (message: string): void => {
        this.dialog.open(ErrorDialogComponent, { data: { message, title: 'title.error' } });
    };
}
