import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LayoutStoreService } from 'admin-core/services';
import { HREF_API, HREF_API_CUSTOMERS, HREF_API_CUSTOMERS_STATUS_COUNT } from 'admin-shared/models';
import { isStringNotEmpty } from 'admin-shared/toolkit/string.toolkit';
import { Observable, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiRequestInterceptor implements HttpInterceptor {
    private reqCount: number = 0;

    constructor(private layoutStoreService: LayoutStoreService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isApiReq = isStringNotEmpty(request.url) && request.url.startsWith(HREF_API);
        const isCustomersGet = request.method === 'GET' && isApiReq && request.url.startsWith(HREF_API_CUSTOMERS) && !request.url.startsWith(HREF_API_CUSTOMERS_STATUS_COUNT);

        if (isApiReq) {
            if (++this.reqCount > 0) {
                this.layoutStoreService.setRequesting();
            }
        }

        return next.handle(request).pipe(
            tap((response: HttpResponse<any>) => (this.toggleReqCount(isApiReq, isCustomersGet))),
            catchError((error: HttpErrorResponse) => {
                this.toggleReqCount(isApiReq, false);
                throw error;
            }),
        );
    }

    private toggleReqCount(isApiReq: boolean, isCustomersGet: boolean) {
        if (isApiReq && !isCustomersGet) {
            if (this.reqCount > 0) {
                this.reqCount--;
            }

            if (this.reqCount === 0) {
                this.layoutStoreService.stopRequesting();
            }
        }
    }
}
