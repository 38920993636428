import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserAuthoritiesService } from 'admin-core/store';
import { CustomerStatus, SecuredDomain } from 'admin-shared/models';
import { asArray } from 'admin-shared/toolkit/array.toolkit';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    selector: 'table-actions',
    templateUrl: './table-actions.component.html',
    styleUrls: ['./table-actions.component.scss'],
})
export class TableActionsComponent implements OnInit {
    @Input() disableBtnAdd: boolean = false;

    @Input() showBtnAdd: boolean = true;
    @Input() showFilter: boolean = true;

    @Input() customerStatus: CustomerStatus;
    @Input() securedDomain: SecuredDomain | SecuredDomain[];

    @Output() add: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() delete: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() filterChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() defaultFilterChanged: EventEmitter<string> = new EventEmitter<string>();

    canCreate$: Observable<boolean>;

    private securedDomain$: BehaviorSubject<SecuredDomain[]> = new BehaviorSubject<SecuredDomain[]>([]);

    constructor(private authoritiesService: UserAuthoritiesService) {
        this.canCreate$ = this.authoritiesService.canCreate$(this.securedDomain$);
    }

    ngOnInit(): void {
        this.securedDomain$.next(asArray(this.securedDomain));
    }

    applyFilter(filterValue) {
        this.filterChanged.next(filterValue);
    }

    clickAdd() {
        this.add.next(true);
    }

    clickDelete() {
        this.delete.next(true);
    }

    changeDefaultFilter(event) {
        this.defaultFilterChanged.next(event);
    }
}
