<mat-toolbar color="primary" class="fix-nav">
    <button type="button" mat-icon-button class="menu-btn" (click)="toggleSidebar()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <div class="nav-brand">
        Luck IP TV Admin {{'placeholder.contact.data' | translate}}
    </div>
    <span class="nav-spacer"></span>

    <button class="hidden-sm" mat-icon-button [matMenuTriggerFor]="profile">
        <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #profile="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
            <mat-icon>person</mat-icon>
            <span>Profile</span>
        </button>
<!--        <button mat-menu-item>-->
<!--            <mat-icon>inbox</mat-icon>-->
<!--            <span>Inbox</span>-->
<!--        </button>-->
<!--        <button mat-menu-item>-->
<!--            <mat-icon>settings</mat-icon>-->
<!--            <span>Settings</span>-->
<!--        </button>-->
    </mat-menu>
    <button mat-icon-button (click)="onLogout()">
        <mat-icon>exit_to_app</mat-icon>
    </button>
</mat-toolbar>
