import { Injectable, Injector } from '@angular/core';
import { HREF_API_CURRENCIES_EDIT } from 'admin-shared/models';
import { ReferenceData } from 'admin-shared/models/reference-data.model';
import { ListStoreService } from 'admin-shared/services/abstract-store.service';

@Injectable()
export class CurrencySettingsStoreService extends ListStoreService<ReferenceData> {
    constructor(protected injector: Injector) {
        super({ injector: injector, baseHref: HREF_API_CURRENCIES_EDIT });
    }
}
