import { InjectionToken } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Identifiable } from 'admin-shared/models/generic.models';

export enum ContactType {
    EMAIL = 'EMAIL',
    FACEBOOK = 'FACEBOOK',
    PHONE = 'PHONE',
    VIBER = 'VIBER',
    WHATSAPP = 'WHATSAPP'
}

export enum CustomerStatus {
    ACTIVE = 'active',
    EXPIRED = 'expired'
}

export const LOAD_FOR_CUSTOMERS_STATUS = new InjectionToken<CustomerStatus>('LIST_CUSTOMERS_STATUS');

export const CONTACT_ICONS = {
    EMAIL: 'alternate_email_icon',
    FACEBOOK: 'facebook_icon',
    PHONE: 'phone_icon',
    VIBER: null,
    WHATSAPP: 'whatsapp',
};

export const PROGRESS_COLORS = {
    accent: 3,
    warn: 0,
};

export interface ContactTypeSelectOption {
    icon: string;
    label?: string;
    token?: string;
    type: ContactType;
}

export const CONTACT_TYPE_OPTIONS: ContactTypeSelectOption[] = [
    { type: ContactType.EMAIL, icon: CONTACT_ICONS[ContactType.EMAIL] },
    { type: ContactType.FACEBOOK, icon: CONTACT_ICONS[ContactType.FACEBOOK] },
    { type: ContactType.PHONE, icon: CONTACT_ICONS[ContactType.PHONE] },
    { type: ContactType.VIBER, icon: CONTACT_ICONS[ContactType.VIBER] },
    { type: ContactType.WHATSAPP, icon: CONTACT_ICONS[ContactType.WHATSAPP] },
];

export interface Contact extends Identifiable {
    contactType: ContactType | string;
    contactValue: string;
}

export interface PersonalData extends Identifiable {
    name: string;
    contacts: Contact[];
}

export interface Contract extends Identifiable {
    additionalDays: number;
    deviceQuantity: number;
    duration: number;
    price: string;
    currency: string;

    startDate: Date;
    endDate: Date;

    progressValue?: number;
    progressColor?: ThemePalette;
    restDays?: number;
}

export interface TvSubscription extends Identifiable {
    token: string;
    username: string;
    password: string;

    deviceApplications: string;
    deviceMac: string;
    tvSource: string;
}

export interface Customer extends Identifiable {
    data: PersonalData;
    contract: Contract;
    subscription: TvSubscription;
    foundMethod: string;
    paymentType: string;
}

export interface ContractStatusCount {
    ACTIVE: number;
    EXPIRED: number;
}

export interface FlatCustomer extends Identifiable {
    name: string;
    contacts: Contact[];

    deviceQuantity: number;
    duration: number;
    price: string;
    currency: string;

    startDate: Date;
    endDate: Date;

    progressValue?: number;
    progressColor?: ThemePalette;
    restDays?: number;

    filterTags: string[];

    token: string;
    username: string;
    password: string;

    deviceApplications: string;
    deviceMac: string;
    tvSource: string;

    foundMethod: string;
    paymentType: string;

    customer?: Customer;
}
