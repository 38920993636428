export const CUSTOMER_TABLE_COLUMNS = [
    'name',

    'startDate',
    'endDate',
    'duration',

    'price',
    'paymentType',
    'deviceQuantity',

    'tvSource',
    'token',

    'contacts',
    'foundMethod',
];

export const ACTION_COLUMN_NAME = 'action';

export const SUBSCRIPTION_STICKY_COLUMNS = [
    'name'
]

export const USER_TABLE_COLUMNS = [
    'username',
    'name',
    'rights',
    'createdAt',
    'updatedAt',
];
