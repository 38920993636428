import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HREF_API, Identifiable } from './generic.models';

export interface ReadonlyService<T> {
    /**
     * Read the item using the provided item UUID
     */
    read: (uuid: string) => T | Observable<T> | void;

    /**
     * read the list of items
     */
    list: () => T[] | Observable<T[]> | void;
}

export interface CRUDService<T> extends ReadonlyService<T> {
    /**
     * Create provided item
     */
    create: (item: T) => T | Observable<T> | void;

    /**
     * Update the provided item
     */
    update: (item: T) => T | Observable<T> | void;

    /**
     * Delete the provided item
     */
    delete: (item: T) => T | Observable<T> | void;
}

export interface AuditableReadonlyService<T extends Identifiable> extends ReadonlyService<T> {
}

export interface AuditableCRUDService<T extends Identifiable> extends AuditableReadonlyService<T> {
}

export abstract class HttpCRUDService<T extends Identifiable> implements AuditableCRUDService<T> {
    protected modelBaseHref = HREF_API;

    protected constructor(private http: HttpClient) {
    }

    public list(): Observable<T[]> {
        return this.http.get<T[]>(this.modelBaseHref).pipe(map(this.mapItemList));
    }

    public create(entity: T): Observable<T> {
        return this.http.post<T>(this.modelBaseHref, entity)
            .pipe(map(this.mapItem));
    }

    public read(uuid: string): Observable<T> {
        return this.http.get<T>(this.setHrefUuid(uuid))
            .pipe(map(this.mapItem));
    }

    public update(entity: T): Observable<T> {
        return this.http.put<T>(this.setHrefUuid(entity.uuid), entity)
            .pipe(map(this.mapItem));
    }

    public delete(entity: T): Observable<T> {
        return this.http.delete<T>(this.setHrefUuid(entity.uuid))
            .pipe(map(this.mapItem));
    }

    protected mapItem = (item: T) => {
        return {
            ...item,
        };
    };

    protected mapItemList = (entityList: T[]): T[] => {
        return entityList.map(this.mapItem);
    };

    private setHrefUuid(uuid: string): string {
        return `${this.modelBaseHref}/${uuid}`;
    }
}
