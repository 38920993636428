import { Injectable, Injector } from '@angular/core';
import { ContractStatusCount, HREF_API_CUSTOMERS_STATUS_COUNT } from 'admin-shared/models';
import { EntityStoreServiceReadonly } from 'admin-shared/services/abstract-store.service';

@Injectable()
export class ContractStatusCountStateService extends EntityStoreServiceReadonly<ContractStatusCount> {
    constructor(protected injector: Injector) {
        super({ injector: injector, baseHref: HREF_API_CUSTOMERS_STATUS_COUNT });
    }
}
