import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SecurityService } from 'admin-core/services';

@Component({
    selector: 'app-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
    @Output() sideNavToggled = new EventEmitter<void>();

    constructor(private securityService: SecurityService) {
    }

    ngOnInit() {
    }

    toggleSidebar() {
        this.sideNavToggled.emit();
    }

    onLogout() {
        this.securityService.logout();
    }
}
