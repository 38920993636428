import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SharedModule } from 'admin-shared/shared.module';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() core: CoreModule) {
        if (core) {
            throw new Error('Core Module can only be imported to AppModule.');
        }
    }
}
