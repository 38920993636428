import { Component } from '@angular/core';
import { LayoutStoreService } from 'admin-core/services/layout-store.service';
import { SecuredDomain } from 'admin-shared/models';
import { ContractStatusCountStateService } from 'admin-core/store/contract-status-count-state.service';
import { isDefined } from 'admin-shared/toolkit/object.toolkit';
import { isStringNotEmpty } from 'admin-shared/toolkit/string.toolkit';
import { Observable, withLatestFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
    sideNavOpened = false;
    toolBarHeight = 64;

    isLoading$: Observable<boolean> = this.layoutStoreService.isRequesting$; //.pipe(map(() => true));

    screenTitle$: Observable<string> = this.layoutStoreService.title$;
    showTitle$: Observable<boolean> = this.screenTitle$.pipe(
        map(isStringNotEmpty),
    );

    showCount$: Observable<boolean> = this.screenTitle$.pipe(
        map(screenTitle => isStringNotEmpty(screenTitle) &&
            (screenTitle.includes(SecuredDomain.SCREEN_CUSTOMERS) || screenTitle.includes(SecuredDomain.SCREEN_CUSTOMERS_EXPIRED))),
    );
    customerCount$: Observable<number> = this.statusCountService.data$.pipe(
        withLatestFrom(this.screenTitle$, this.showCount$),
        filter(([statusCount, , showCount]) => isDefined(statusCount) && showCount === true),
        map(([statusCount, screenTitle]) => (screenTitle.includes(SecuredDomain.SCREEN_CUSTOMERS) ? statusCount.ACTIVE : statusCount.EXPIRED)),
    );

    constructor(private layoutStoreService: LayoutStoreService, private statusCountService: ContractStatusCountStateService) {

    }

    onClickLink() {
        this.sideNavOpened = false;
    }
}
