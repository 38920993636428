import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from 'admin-shared/layout';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
            },
            {
                path: 'customers',
                loadChildren: () => import('./features/customers/customers.module').then(m => m.CustomersModule),
                data: {
                    screenTitle: 'label.screen.CUSTOMERS',
                },
            },
            {
                path: 'application-settings',
                loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsModule),
                data: {
                    screenTitle: 'label.screen.APPLICATION_SETTINGS',
                },
            },
            {
                path: 'user-settings',
                loadChildren: () => import('./features/user-settings/user-settings.module').then(m => m.UserSettingsModule),
                data: {
                    screenTitle: 'label.screen.USER_SETTINGS',
                },
            },
        ],
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./login/login.module').then((m) => m.LoginModule),
    },
    {
        path: '**',
        component: PageNotFoundComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
