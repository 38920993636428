<div class="grid grid-cols-2 mb-3 flex content-center">
    <div class="px-5">
        <mat-form-field *ngIf="showFilter" class="w-100">
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'placeholder.filter' | translate}}">
        </mat-form-field>
    </div>

    <div class="flex flex-row items-center justify-end">
        <div *ngIf="!!customerStatus" class="mx-5">
            <mat-radio-group class="tbl-action-radio-group"
                             aria-label="Filtru initial"
                             (ngModelChange)="changeDefaultFilter($event)"
                             [(ngModel)]="customerStatus">
                <mat-radio-button class="tbl-action-radio-button" value="active">
                    {{'label.option.active' | translate}}
                </mat-radio-button>
                <mat-radio-button class="tbl-action-radio-button" value="expired">
                    {{'label.option.expired' | translate}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div *ngIf="showBtnAdd && (canCreate$ | async)" class="mx-3">
            <button mat-stroked-button [disabled]="disableBtnAdd" (click)="clickAdd()">
                <span class="px-2" translate="btn.add"></span>
                <mat-icon aria-label="Create">person_add_alt</mat-icon>
            </button>
        </div>
    </div>
</div>
