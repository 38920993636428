import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserAuthoritiesService } from 'admin-core/store';
import { MENU_ROUTES } from '../../constants/admin-routes';

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
    @Output() clickLink: EventEmitter<void> = new EventEmitter();

    routes = MENU_ROUTES;

    constructor(public authoritiesService: UserAuthoritiesService) {}

    ngOnInit() {}

    onClickLink() {
        this.clickLink.next();
    }
}
