import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { StateStatus, ViewState } from 'admin-shared/models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LayoutStoreService extends ComponentStore<ViewState<string>> {
    public readonly isRequesting$ = this.select(s => s.status === StateStatus.REQUESTING);
    public readonly title$ = this.select(s => s.entity) as Observable<string>;

    public clearTitle = () => this.patchState({ entity: null });
    public setTitle = (title: string = null) => this.patchState({ entity: title });

    public setRequesting = () => {
        this.requestTime = this.requestTime || new Date().getTime();
        this.patchState({ status: StateStatus.REQUESTING });
    };
    public stopRequesting = (timeoutToSet?: number) => {
        let timeout = timeoutToSet > 0 ? timeoutToSet : new Date().getTime() - this.requestTime;
        setTimeout(() => this.patchState({ status: StateStatus.NONE }), timeout > 350 ? 0 : 350);
    };

    private requestTime: number;

    constructor() {
        super({ entity: null, status: StateStatus.NONE });
    }
}
