
export const asDefinedObject = <T>(obj: T = {} as T): T => !!obj ? obj : {} as T;

export const isUndefined = (o) => {
    return checkType(o, 'undefined');
};

export const isDefined = (val) => {
    return !isUndefined(val) && val !== null;
};

export const isNotEmpty = (val) => {
    return (isDefined(val) && val.length > 0);
};

export const isEmpty = (val) => {
    return !isNotEmpty(val);
};

export const isNotObject = (o) => {
    return !isObject(o);
};

export const isObject = (o) => {
    return checkType(o, 'object');
};

export const checkType = (o: any, type: string): boolean => {
    return typeof o === type;
};
