import { Customer, DefaultItem } from 'admin-shared/models';
import { asArray } from 'admin-shared/toolkit/array.toolkit';
import { dateTimeToString, dateToString, stringToDate } from 'admin-shared/toolkit/date.toolkit';
import { isDefined } from 'admin-shared/toolkit/object.toolkit';
import { isDate } from 'date-fns';

export const setDateToString = <T>(item: T | Date): T | string => {
    if (isDefined(item) && typeof item === 'object') {
        if (isDate(item)) {
            return dateToString(item as Date);
        }

        if (Array.isArray(item)) {
            item.forEach(arrayItem => setDateToString(arrayItem));
        } else {
            Object.keys(item).forEach(key => item[key] = setDateToString(item[key]));
        }
    }

    return item as T;
};

export const auditableDateToString = <T extends DefaultItem>(item: T): T => {
    if (!!item['createdAt']) {
        item['createdAt'] = dateTimeToString(item['createdAt']);
    }

    if (!!item['updatedAt']) {
        item['updatedAt'] = dateTimeToString(item['updatedAt']);
    }

    return item;
};

export const parseAuditableDate = <T extends DefaultItem>(item: T): T => {
    if (!!item['createdAt']) {
        item['createdAt'] = stringToDate(item['createdAt']);
    }

    if (!!item['updatedAt']) {
        item['updatedAt'] = stringToDate(item['updatedAt']);
    }

    return item;
};

export const deleteUuidIfNull = (item: any): void => {
    if (item.uuid === null) {
        delete item.uuid;
    }
};

export const cleanCustomerUuidFields = (item: Customer): Customer => {
    deleteUuidIfNull(item);
    deleteUuidIfNull(item.data);
    deleteUuidIfNull(item.contract);
    deleteUuidIfNull(item.subscription);

    asArray(item.data.contacts).forEach(deleteUuidIfNull);

    return item;
};
