<div fxFlexFill class="data-loading-shade" *ngIf="isLoading$ | async">
    <mat-spinner></mat-spinner>
</div>

<div class="layout-container">
    <app-top-nav (sideNavToggled)="sideBarNav.toggle()"></app-top-nav>

    <mat-sidenav-container class="sidenav-container" [style.marginTop.px]="toolBarHeight">
        <mat-sidenav #sideBarNav class="sidenav mat-elevation-z10" mode="over" [(opened)]="sideNavOpened">
            <app-side-nav (clickLink)="onClickLink()"></app-side-nav>
        </mat-sidenav>
        <mat-sidenav-content>
            <main fxFlexFill class="main-container">
                <h2 class="screen-title" *ngIf="(showTitle$ | async)">
                    {{screenTitle$ | async | translate}}&nbsp;<span *ngIf="showCount$ | async">({{customerCount$ | async}})</span>
                </h2>
                <router-outlet></router-outlet>
            </main>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
