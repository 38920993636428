import { DATE_FORMAT, DATETIME_FORMAT } from 'admin-shared/models';
import { isStringNotEmpty } from 'admin-shared/toolkit/string.toolkit';
import { format, isMatch, parse } from 'date-fns';

export const currentDate = (): string => dateToString(new Date());
export const currentDateTime = (): string => dateTimeToString(new Date());

export const dateToStringFormat = (date: Date, dateFormat: string): string => !!date ? format(date, dateFormat) : null;
export const dateTimeToString = (date: Date): string => dateToStringFormat(date, DATETIME_FORMAT);
export const dateToString = (date: Date): string => dateToStringFormat(date, DATE_FORMAT);

export const stringToDateFormat = (str: string, dateFormat: string): Date => isStringNotEmpty(str) && isMatch(str, dateFormat) ? parse(str, dateFormat, new Date()) : null;
export const stringToDateTime = (str: string): Date => stringToDateFormat(str, DATETIME_FORMAT);
export const stringToDate = (str: string): Date => stringToDateFormat(str, DATE_FORMAT);
