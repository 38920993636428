import { Component, Input, OnInit } from '@angular/core';
import { UserAuthoritiesService } from 'admin-core/store';
import { SecuredDomain } from 'admin-shared/models';
import { asArray } from 'admin-shared/toolkit/array.toolkit';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    selector: 'secured-content',
    templateUrl: './secured-content.component.html',
    styleUrls: ['./secured-content.component.scss'],
})
export class SecuredContentComponent implements OnInit {
    @Input() securedDomain: SecuredDomain | SecuredDomain[] = [];

    canRead$: Observable<boolean>;

    private securedDomain$: BehaviorSubject<SecuredDomain[]> = new BehaviorSubject<SecuredDomain[]>([]);

    constructor(private authoritiesService: UserAuthoritiesService) {
        this.canRead$ = this.authoritiesService.canRead$(this.securedDomain$);
    }

    ngOnInit(): void {
        this.securedDomain$.next(asArray(this.securedDomain));
    }
}
