

export const HREF_API = '/api';
export const HREF_API_EDIT = `${HREF_API}/edit`;
export const HREF_SECURITY = `${HREF_API}/security`;
export const HREF_API_CUSTOMERS = `${HREF_API}/customers`;
export const HREF_API_CUSTOMERS_STATUS_COUNT = `${HREF_API}/customers-count-status`;

export const HREF_API_REF_DATA = `${HREF_API}/reference-data`;
export const HREF_API_REF_DATA_EDIT = `${HREF_API_EDIT}/reference-data`;

export const HREF_API_CURRENCIES = `${HREF_API_REF_DATA}/currencies`;
export const HREF_API_CURRENCIES_EDIT = `${HREF_API_REF_DATA_EDIT}/currencies`;

export const HREF_API_USERS = `${HREF_API}/users`;
export const HREF_API_ROLES = `${HREF_API}/roles`;

export const DATE_FORMAT = 'dd/MM/yyyy';
export const DATETIME_FORMAT = 'dd/MM/yyyy HH:mm:ss';

export const LUK_MAT_DATE_FORMATS = {
    parse: {
        dateInput: 'dd/MM/yyyy',
    },
    display: {
        dateInput: 'dd/MM/yyyy',
        monthYearLabel: 'MMM yyyy',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMM yyyy',
    },
};

export interface DefaultItem {
    id?: number;
    version?: number;
}

export interface Identifiable extends DefaultItem {
    uuid?: string;
}

export interface DialogData {
    title: string;
    message: string;
}
