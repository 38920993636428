import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'static-form-field',
  templateUrl: './static-form-field.component.html',
  styleUrls: ['./static-form-field.component.scss']
})
export class StaticFormFieldComponent implements OnInit {
  @Input() label: string;
  @Input() value: any;

  constructor() { }

  ngOnInit(): void {
  }

}
