import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SecurityService } from 'admin-core/services';
import { HREF_API } from 'admin-shared/models';
import { isStringNotEmpty } from 'admin-shared/toolkit/string.toolkit';
import { Observable } from 'rxjs';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private securityService: SecurityService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authReq = request;

        // add auth header with jwt if user is logged in and request is to the api url
        const user = this.securityService.userValue;
        const isApiUrl = request.url.startsWith(HREF_API);

        if (!!user && isStringNotEmpty(user.token) && isApiUrl) {
            authReq = request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + user.token) });
        }

        return next.handle(authReq);
    }
}
