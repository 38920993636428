import { SecuredDomain } from 'admin-shared/models';
import { APP_SCREENS } from 'admin-shared/models/application.models';

export const ROOT_SCREEN_TITLES = {
    '/customers': 'label.screen.CUSTOMERS',
    '/customers/expired': 'label.screen.CUSTOMERS_EXPIRED',
    '/application-settings': 'label.screen.SETTINGS',
    '/user-settings': 'label.screen.USER_SETTINGS',
};

export const MENU_ROUTES = [
    {
        data: { icon: 'dashboard', iconClass: '', text: 'label.screen.SCREEN_DASHBOARD' },
        divider: true,
        path: 'dashboard',
        screen: APP_SCREENS.DASHBOARD,
        securedDomain: SecuredDomain.SCREEN_CUSTOMERS,
    },
    {
        data: { icon: 'assignment', iconClass: 'success', text: 'label.screen.SCREEN_CUSTOMERS' },
        path: 'customers',
        screen: APP_SCREENS.CUSTOMERS,
        securedDomain: SecuredDomain.SCREEN_CUSTOMERS,
    },
    {
        data: { icon: 'cancel_presentation', iconClass: 'warn', text: 'label.screen.SCREEN_CUSTOMERS_EXPIRED' },
        divider: true,
        path: 'customers/expired',
        screen: APP_SCREENS.CUSTOMERS_EXPIRED,
        securedDomain: SecuredDomain.SCREEN_CUSTOMERS_EXPIRED,
    },
    {
        data: { icon: 'settings', iconClass: 'secondary', text: 'label.screen.SCREEN_APPLICATION_SETTINGS' },
        path: 'application-settings',
        screen: APP_SCREENS.APPLICATION_SETTINGS,
        securedDomain: SecuredDomain.SCREEN_APPLICATION_SETTINGS,
    },
    {
        data: { icon: 'face', iconClass: 'info', text: 'label.screen.SCREEN_USER_SETTINGS' },
        path: 'user-settings',
        screen: APP_SCREENS.USER_SETTINGS,
        securedDomain: SecuredDomain.SCREEN_USER_SETTINGS,
    },
];
