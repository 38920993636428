import { DefaultItem } from 'admin-shared/models/generic.models';

export const EXPIRE_KEY = 'expires-in';
export const TOKEN_KEY = 'auth-token';
export const USER_KEY = 'auth-user';

export interface User extends DefaultItem {
    email: string;
    name: string;
    username: string;
    password?: string;
    authorities: GrantedAuthority[];
    token?: string;
}

export interface AuthenticationResult {
    expiresIn: number;
    user: User;
}

export enum SecuredDomain {
    SCREEN_APPLICATION_SETTINGS = 'SCREEN_APPLICATION_SETTINGS',
    SCREEN_CUSTOMERS = 'SCREEN_CUSTOMERS',
    SCREEN_CUSTOMERS_EXPIRED = 'SCREEN_CUSTOMERS_EXPIRED',
    SCREEN_USER_SETTINGS = 'SCREEN_USER_SETTINGS',
    SCREEN_DASHBOARD = 'SCREEN_DASHBOARD',

    CONTRACT = 'CONTRACT',
    CUSTOMER = 'CUSTOMER',
    PERSONAL_DATA = 'PERSONAL_DATA',
    SUBSCRIPTION = 'SUBSCRIPTION',

    APPLICATION_SETTINGS = 'APPLICATION_SETTINGS',
    USER = 'USER',
}

export const SECURED_APP_SCREENS = [
    SecuredDomain.SCREEN_CUSTOMERS,
    SecuredDomain.SCREEN_CUSTOMERS_EXPIRED,
    SecuredDomain.SCREEN_APPLICATION_SETTINGS,
    SecuredDomain.SCREEN_USER_SETTINGS,
];

export const SECURED_DOMAIN_OBJ = [
    SecuredDomain.CONTRACT,
    SecuredDomain.CUSTOMER,
    SecuredDomain.PERSONAL_DATA,
    SecuredDomain.SUBSCRIPTION,
    SecuredDomain.APPLICATION_SETTINGS,
    SecuredDomain.USER,
];

export const SECURED_DOMAIN_LIST = [
    ...SECURED_APP_SCREENS,
    ...SECURED_DOMAIN_OBJ,
];

export enum AuthorityAction {
    READ = 'authRead',
    CREATE = 'authCreate',
    UPDATE = 'authUpdate',
    DELETE = 'authDelete'
};

export const AUTHORITY_PROPERTIES: string[] = [
    AuthorityAction.CREATE,
    AuthorityAction.READ,
    AuthorityAction.UPDATE,
    AuthorityAction.DELETE,
];

export const AUTHORITY_ICONS = {
    authCreate: 'add',
    authCreateOff: 'do_not_disturb_on',
    authRead: 'visibility_alt',
    authReadOff: 'visibility_off_outlined',
    authUpdate: 'edit_outlined',
    authUpdateOff: 'do_not_disturb_on',
    authDelete: 'delete_outline',
    authDeleteOff: 'do_not_disturb_on',
};

export interface GrantedAuthority extends DefaultItem {
    authDomain: SecuredDomain;
    authRead: boolean;
    authCreate: boolean;
    authDelete: boolean;
    authUpdate: boolean;
}

export const GRANTED_AUTHORITY_DRAFT: Partial<GrantedAuthority> = {
    authDomain: null,
    authRead: false,
    authCreate: false,
    authDelete: false,
    authUpdate: false,
};


