import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApiRequestInterceptor } from 'admin-core/interceptors/api-request-interceptor.service';
import { ErrorInterceptor } from 'admin-core/interceptors/error.interceptor';
import { JwtInterceptor } from 'admin-core/interceptors/jwt.interceptor';
import { ADMIN_STORE_SERVICES } from 'admin-core/store';
import { APP_SERVICE } from 'admin-shared/services';
import { httpLoaderFactory } from 'admin-shared/toolkit/http.toolkit';
import { ro } from 'date-fns/locale';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ADMIN_SERVICES } from './core/services';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions(),
        CoreModule,
        TranslateModule.forRoot({
            defaultLanguage: 'ro',
            useDefaultLang: true,
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient],
            },
            isolate: false,
            extend: true,
        }),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ApiRequestInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: MAT_DATE_LOCALE, useValue: ro },
        ...ADMIN_SERVICES,
        ...ADMIN_STORE_SERVICES,
        ...APP_SERVICE,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(public translateService: TranslateService) {
        this.translateService.use('ro');
    }
}
