export const asArray = <T>(obj: T | T[]): T[] => {
    if (!!obj) {
        return Array.isArray(obj) ? obj : [obj];
    }
    return [];
};

export const isArrayNotEmpty = <T>(obj: T[]): boolean => {
    return asArray(obj).length > 0;
};
