import { SideNavComponent } from './side-nav/side-nav.component';
import { LayoutComponent } from './layout.component';
import { TopNavComponent } from './top-nav/top-nav.component';

export const LAYOUT_COMPONENTS = [
    LayoutComponent,
    SideNavComponent,
    TopNavComponent
];

export * from './side-nav/side-nav.component';
export * from './layout.component';
export * from './top-nav/top-nav.component';
