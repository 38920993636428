import { Injector } from '@angular/core';
import { SecuredDomain } from 'admin-shared/models/security.models';

import { Observable, Subscription } from 'rxjs';

export enum StateStatus {
    ERROR,
    LOADED,
    NONE,
    NOT_LOADED,
    REQUESTING,
}

export interface ViewState<T> {
    entity: T | T[];
    status: StateStatus;
}

export interface ConfigStoreService {
    baseHref?: string;
    doInit?: boolean;
    idProperty?: string;
    injector?: Injector;
    loadData?: boolean;
    securedDomain?: SecuredDomain;
}

export interface StoreServiceReadonly<T> {
    load: () => void;
}

export interface StoreService<T> extends StoreServiceReadonly<T> {
    create: T extends void ? ((observableOrValue?: (void | Observable<void>)) => Subscription) : ((observableOrValue: (T | Observable<T>)) => Subscription);
    update: T extends void ? ((observableOrValue?: (void | Observable<void>)) => Subscription) : ((observableOrValue: (T | Observable<T>)) => Subscription);
    delete: T extends void ? ((observableOrValue?: (void | Observable<void>)) => Subscription) : ((observableOrValue: (T | Observable<T>)) => Subscription);
    save: (item: void & T) => Subscription;
}

export interface Store<T> {
    readonly state$: Observable<T | T[]>;
    readonly status$: Observable<StateStatus>;

    readonly value: T | T[];
    readonly isRequesting: boolean;
    readonly isLoaded: boolean;

    reset: () => void;
    select: <K>(mapFn: (state: T | T[]) => K | K[] | T | T[]) => Observable<K | K[] | T | T[]>;
}
